import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import NotFound from '@components/NotFound/NotFound';

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Ops! Niente E-Bike qui! - Tiziano Bieller Sport" lang="it" />
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;

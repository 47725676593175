import React from 'react';
import { navigate } from 'gatsby';

import Button from '@components/Button/Button';

import * as styles from './NotFound.module.css';

const NotFound = () => {
  const onClick = () => {
    navigate('/ebike');
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Ops... Non ci sono E-Bike qui</h1>
      <p className={styles.text}>
        Clicca il bottone qui sotto per vedere tutte le nostre E-Bike e
        scegliere la tua preferita!
      </p>
      <Button type="secondary" onClick={onClick}>
        SCEGLI LA TUA EBIKE
      </Button>
    </div>
  );
};

export default NotFound;
